<template>

  <Layout>

    <b-row
      class="d-flex flex-row align-items-stretch justify-content-between w-100 body-container"
      no-gutters
    >

      <b-col cols="12" lg="5" class="d-flex flex-column logo-container py-4">

        <b-col
          cols="10"
          class=" d-flex flex-column align-self-center align-items-center justify-content-center text-container"
        >
       
        <h3 class="font-primary font-weight-bold">
          {{ selectedLanguage === 'Samoan' ?  'TalAfio mai i leofa' : ' Welcome to' }}
         
        </h3>
        
        <!-- <div class="logo-box">
          <b-img
            src="@/assets/Asset 12.png"
            class="logo-img"
            alt="Finance"
          ></b-img>
        </div> -->
         <div class="logo-box">
          <b-img
            src="@/assets/welcomePageIcon.png"
            class="logo-img"
            alt="Finance"
          ></b-img>
          
        </div>
        <h3 class="font-primary font-weight-bold">
          {{ selectedLanguage === 'Samoan' ?  'Tusiteu (Figabook)' : ' Figabooks'  }}
          
        </h3>
        <div class="welcometext">  
          
        <p class="thicker" style="text-align:center" v-if="selectedLanguage === 'Samoan'">
          O le Figabooks poo le Tusiteu e sili atu <br/>nai lo se faiga masani tau tupe.<br/><br/>
          O lau polokalame fa'akomepiuta fa'afaigfie mo le<br/>SMEs, Tu ma aga, Amataina, Kalapu ma  Galuega alofa.<br/><br/>Matou te tu'uina atu auaunaga fa'aopoopo,<br/> pisinisi ma fa'amatalaga tau tupe, faiga fa'akomepiuta <br/>ma isi fesoasoani fa'apitoa <br/>ma fofo o fa'afitauli.

         
        </p>
        <p class="thicker" style="text-align:center" v-else>
          Figabooks is much more than a traditional<br/>accounting approach.<br/><br/>
          It is your accounting software made easy for<br/>SMEs, Tradies, Startups, Clubs & Charities.<br/><br/>We deliver value-added services, business<br/>accounting expertise, accounting systems<br/>and other virtual support and cloud-based<br/>solutions.
        </p>
        </div>
        </b-col>

      </b-col>

      <b-col
        cols="12"
        lg="7"
        class="d-flex flex-column align-items-center justify-content-start justify-content-lg-center px-0 py-3 inner-container"
      >

        <slot></slot>

      </b-col>

    </b-row>

  </Layout>

</template>

<script>
 import Layout from "@/layout/Layout"; export default {

  props: {
    selectedLanguage: String
  },
   components: { Layout, },
};
</script>

<style scoped>
.body-container {
  display: flex;
  min-height: 600px;
  height: 100vh;
}
.body-container .logo-container {
  /* height: 100vh; */
  min-height: 600px;
  /* background-color: var(--primary); */
  background-color: #FFE400;
}
.text-container{
  display: flex;
  min-height: 90vh;
  padding-top: 100px;  
  width: auto;
 
}
.body-container .inner-container {
  /* height: 100vh; */
  min-height: 600px;
  background-color: var(--white);
}
.logo-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 86px;
  height: 96px;
  padding-top: 90px;
  padding-bottom: 40px;
  /* padding: 50px 70px 50px 70px;
  /* background: rgba(255, 255, 255, 0.25); */
  /* background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12.5px);
  border-radius: 15px;  */
}
.logo-img {
  width: 86px;
}
/* .image-outline { */
  /* width: 250px;
  height: 200px; */
  /* align-items: center; */
/* } */

   p{
     
      font-weight:400;
      font-size: 18px;
      padding-top:30px;
    } 

    h3{
      font-weight:600;
      font-size: 36px;

    }
    

 /* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .body-container .logo-container {
    height: auto;
    overflow: hidden;
  }
  .body-container .logo-container .logo-box{
    height: 46px;
    width: 46px;
    /* padding: 10px 15px 10px 15px; */
  }
  .logo-img{
    width: 40px;
  }
  .body-container .inner-container {
    height: 75vh;
  }
  .logo-footer-img {
    height: 40px;
  }
  .text-main {
    font-size: 13px;
  }
} /* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .body-container .logo-container {
    height: 40vh;
  }
  .body-container .logo-container .logo-box {
    height: 46px;
    width: 46px;
    /* padding: 10px 15px 10px 15px; */
  }
  .logo-img{
    width: 40px;
  }
  .body-container .inner-container {
    height: 80vh;
  }
}
</style>

